/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    // ALL PAGES
    'common': {
      init: function() {

        // Prevents flash of unwanted text
        // Must have .before-load on the HTML el
        $(document).ready(function() {
          $('html').addClass('fade-in');
        });

        // CONTACT UTILITY LINK
        // uses scrollTo to jump to contact area in footer
        $('#menu-item-72').click(function() {
          $.scrollTo($('#emp-contact-footer'), {
            duration: 800,
            offset: -100
          });
          return false;
        });

        // SCROLLING HEADER LINK TO TOP
        $('#emp-fixed-header-logo').click(function() {
          $.scrollTo($('.emp-header'), {
            duration: 1000,
            offset: -100
          });
          return false;
        });
      }
    },

    // NOT HOMEPAGE
    'nothomepage': {
      init: function() {
        // NAV FADE-IN
        var navComesToLife = new Waypoint({
          element: document.getElementById('emp-content-block-1'),
          handler: function(direction) {
            if (direction === 'down') {
              $('#emp-header-anchor').addClass('fade-down-active');
              $('#emp-nav-anchor').addClass('fade-up-active');
              $('.emp-progress-indicator').delay(400).addClass('fade-in');
            }
            if (direction === 'up') {
              $('#emp-header-anchor').removeClass('fade-down-active');
              $('#emp-nav-anchor').removeClass('fade-up-active');
              $('.emp-progress-indicator').removeClass('fade-in');
            }
          },
          offset: '-20%'
        });
      }
    },

    // HOMEPAGE
    'home': {
      init: function() {
        // NAV FADE-IN
        var homeNavComesToLife = new Waypoint({
          element: document.getElementById('emp-homepage-unit-1'),
          handler: function(direction) {
            if (direction === 'down') {
              $('#emp-header-anchor').addClass('fade-down-active');
              $('#emp-nav-anchor').addClass('fade-up-active');
            }
            if (direction === 'up') {
              $('#emp-header-anchor').removeClass('fade-down-active');
              $('#emp-nav-anchor').removeClass('fade-up-active');
            }
          },
          offset: '70%'
        });

        // SVG for HOMEPAGE
        function svgCreation() {
          // Set up logo diamond arrangment
          var empLogoDiamond =      SVG('emp-logo-homepage').size(400, 450);

          // This is clockwise reveal, note the right > bottom > left > top progression
          var empCircleRight =      empLogoDiamond.circle(1).fill('#a6ce39').cx(200).cy(200).opacity(0.1)
                                                  .animate(600, '-', 600).size(200).opacity(0.5).cx(275);
          var empCircleBottom =     empLogoDiamond.circle(1).cx(200).cy(200).fill('#a6ce39').opacity(0.1)
                                                  .animate(600, '-', 1000).size(200).opacity(0.5).cy(275);
          var empCircleLeft =       empLogoDiamond.circle(1).cx(200).cy(200).fill('#a6ce39').opacity(0.1)
                                                  .animate(600, '-', 1400).size(200).opacity(0.5).cx(125);
          var empCircleTop =        empLogoDiamond.circle(1).cx(200).cy(200).fill('#a6ce39').opacity(0.1)
                                                  .animate(600, '-', 200).size(200).opacity(0.5).cy(125);

          // Center square
          var empCenterSquare =     empLogoDiamond.rect(45,45)
                                                  .fill('#2caae2').opacity(0.0).cx(200).cy(200)
                                                  .animate(600, '-', 2400).opacity(0.9);
          var empCenterPoint =      empLogoDiamond.circle(10).fill('#ffffff').opacity(0.0).cx(200).cy(200)
                                                  .animate(400, '-', 3000).opacity(0.9);

          // Mission text
          var empCircleTopText =  empLogoDiamond.text(function(add) {
                                    add.tspan('Mission').fill('#666');
                                    add.font({
                                      family:   'Rift',
                                      size:     25
                                    });
                                  });
          var empCircleTopTextPathStart = 'M 165 90 L 165 90';
          var empCircleTopTextPathEnd = 'M 165 90 L 235 90';
          empCircleTopText.path(empCircleTopTextPathStart).animate(400, '-', 800).plot(empCircleTopTextPathEnd);

          // Resources text
          var empCircleLeftText =  empLogoDiamond.text(function(add) {
                                     add.tspan('Resources').fill('#666');
                                     add.font({
                                       family:   'Rift',
                                       size:     22
                                     });
                                   });
          var empCircleLeftTextPathStart = 'M 35 208 L 35 208';
          var empCircleLeftTextPathEnd = 'M 35 208 L 125 208';
          empCircleLeftText.path(empCircleLeftTextPathStart).animate(400, '-', 2000).plot(empCircleLeftTextPathEnd);

          // Strengths text
          var empCircleBottomText = empLogoDiamond.text(function(add) {
                                      add.tspan('Strengths').fill('#666');
                                      add.font({
                                        family:   'Rift',
                                        size:     22
                                      });
                                    });
          var empCircleBottomTextPathStart = 'M 158 330 L 158 330';
          var empCircleBottomTextPathEnd = 'M 158 330 L 242 330';
          empCircleBottomText.path(empCircleBottomTextPathStart).animate(400, '-', 1600).plot(empCircleBottomTextPathEnd);

          // Structure text
          var empCircleRightText = empLogoDiamond.text(function(add) {
                                      add.tspan('Structure').fill('#666');
                                      add.font({
                                        family:   'Rift',
                                        size:     22
                                      });
                                    });
          var empCircleRightTextPathStart = 'M 280 208 L 280 208';
          var empCircleRightTextPathEnd = 'M 280 208 L 360 208';
          empCircleRightText.path(empCircleRightTextPathStart).animate(400, '-', 1200).plot(empCircleRightTextPathEnd);

          // Economic Sustainability text
          var empEconText = empLogoDiamond.text(function(add) {
                                      add.tspan('Economic Sustainability').fill('#2caae2');
                                      add.font({
                                        family:   'Rift',
                                        size:     30
                                      });
                                    });
          //var empEconTextPathStart = 'M 200 420 L 200 420';
          var empEconTextPathStart = 'M 70 420 L 70 420';
          var empEconTextPathEnd = 'M 70 420 L 330 420';
          empEconText.path(empEconTextPathStart).animate(800, '-', 3400).plot(empEconTextPathEnd);
        }

        // Waypoint for intro
        var introDisappear = new Waypoint({
          element: document.getElementById('emp-homepage-main-content-id'),
          handler: function(direction) {
            if (direction === 'down') {
              $('.emp-scroll-hint').addClass('fade-away');
              //$('.emp-homepage-hero').addClass('fade-away');
            }
            if (direction === 'up') {
              $('.emp-scroll-hint').removeClass('fade-away');
              //$('.emp-homepage-hero').removeClass('fade-away');
            }
          },
          offset: '95%'
        });

        // Special waypoint for the SVG animation
        var SVGRollIn = new Waypoint({
          element: document.getElementById('emp-homepage-unit-svg'),
          handler: function(direction) {
            if (direction === 'down') {
              $('#emp-homepage-unit-svg').addClass('fade-up-active');
              setTimeout(svgCreation(), 500);
            }
            if (direction === 'up') {
              $('#emp-homepage-unit-svg').removeClass('fade-up-active');

              // Get current SVG ID and hide it
              var currentSVGtoHide = $('#emp-logo-homepage svg').attr('id');
              $('#' + currentSVGtoHide).remove();
            }
          },
          offset: '80%'
        });

        // QUESTIONS GROUPS WAYPOINTS (DYNAMIC)
        // Collect waypoints in case we need them
        var waypoints = [];

        // Question page loader
        var questionSets = $('.emp-homepage-unit-container').length;

        $('.emp-homepage-unit-container').each(function(index, value) {

          var that = $(value);

          var waypoint = new Waypoint({
            element: value,
            handler: function(direction) {
              if (direction === 'down') {
                that.addClass('fade-up-active');
              }
              if (direction === 'up') {
                that.removeClass('fade-up-active');
              }
            },
            offset: '70%'
          });
          waypoints.push(waypoint);
        });

      }
    },

    // SINGLE LANDING PAGES
    'singles': {
      init: function() {
        // PROGRESS INDICATOR
        // Calculate height of main object and the window, set max attr of progress accordingly
        var winHeight = $(window).height(),
            sectionHeight = $('.emp-content-block').height(),
            progressBar = $('progress'),
            max,
            value;

        // Set the maximum scrollable area
        max = sectionHeight - winHeight;
        progressBar.attr('max', sectionHeight);

        // Set value attr of progress, changes as user scrolls
        $(document).on('scroll', function(){
          value = $(window).scrollTop();
          progressBar.attr('value', value);
        });
      }
    },

    // LIBRARY
    'library': {
      init: function() {
        // LIBRARY SORT
        // Build multidimensional array of keywords for each library item
        var libraryCardKeywords = [];
        var libraryCardHTML = [];
        var i = 1;

        // GET HTML OF EACH LIBRARY CARD
        $('.emp-card-container').each(function() {

          // Put each card into the HTML array
          libraryCardHTML[i] = $(this);

          // Get the 'data' attribute for each and put it into an array of it's own
          libraryCardTemp = $(this).data();
          libraryCardKeywords[i] = libraryCardTemp.empCardCategories.split(" ").filter(function(i){return i;});
          i++;
        });

        // FIGURE OUT WHICH LIBRARY CARDS ARE WANTED BY USER
        // Set up container for valid positions
        var libraryCardValidPositions = [];

        $('#emp-card-sort').on('change', function(event){

          // Figure out which group was selected
          var keywordActive = $(this).val();

          // Compare selected group with multidimensional array
          for (var j=0; j < libraryCardKeywords.length; j++) {

            // Active library card length
            var libraryCardActive = $(libraryCardKeywords[j]).size();

            // Now we look at the secondary array to see if there is a match
            for (var k=0; k < libraryCardActive; k++) {

              // And we save where each libraryCardHTML match is in libraryCardValidPositions
              // this yields some random undefined elements - not sure why?
              if (keywordActive === libraryCardKeywords[j][k]) {
                libraryCardValidPositions[k] = Number($(libraryCardHTML[j]).attr('id'));
              }
            }
          }

          // SWITCH LIBRARY CARDS AROUND
          var libraryCardTemp;
          var libraryCardOrigin, libraryCardOriginData, libraryCardDestination, libraryCardDestinationData;

          // We need to get the first n elements of libraryCardHTML
          // This allows us to know where to insert the sorted items
          // but libraryCardValidPositions.length returns undefineds and real positions
          // So we create a second counter that only counts when real values get popped out in the loop
          var extraCounter = 0;

          // For each match, we move the cards to the front of the HTML stack for viewing
          for (var l=0; l < libraryCardValidPositions.length; l++) {

            // Avoid undefined positions (which get produced for some reason)
            if (libraryCardValidPositions[l] !== undefined) {

              // Origin cards have the sorted content we want
              // Destination cards have content we want to put back in the stack
              // Get the 'data-emp-card-categories' for each card we want to move
              // Why get the data? Because we are going to switch internal HTML of cards (but not ID attr) and also need to switch data attr of container
              libraryCardOriginData = $(libraryCardHTML[(libraryCardValidPositions[l] + 1)]).attr('data-emp-card-categories');
              libraryCardDestinationData = $(libraryCardHTML[(extraCounter + 1)]).attr('data-emp-card-categories');

              // Switch orign and desimation data attributes
              $(libraryCardHTML[(libraryCardValidPositions[l] + 1)]).attr('data-emp-card-categories', libraryCardDestinationData);
              $(libraryCardHTML[(extraCounter + 1)]).attr('data-emp-card-categories', libraryCardOriginData);

              // Get actual HTML content of cards we want to switch
              libraryCardOrigin = $(libraryCardHTML[(libraryCardValidPositions[l] + 1)]).children();
              libraryCardDestination = $(libraryCardHTML[(extraCounter + 1)]).children();

              // Switch the origin and destination els
              $(libraryCardDestination).delay(200).remove(); // get rids of contents in destination el
              $(libraryCardOrigin).delay(200).remove(); // gets rid of content in origin el
              $(libraryCardHTML[(libraryCardValidPositions[l] + 1)]).append(libraryCardDestination); // put destination HTML into origin el
              $(libraryCardHTML[(extraCounter + 1)]).append(libraryCardOrigin); // put origin HTML into destination el

              // Increment counter
              extraCounter++;
            }
          }
        });

      }
    },

    // GUIDANCE
    'guidance': {
      init: function() {
        // TAB SWITCHER
        // Set up index value for the tabs
        var previousActiveTabIndex = 0;

        // Check to see if a tab has been clicked
        $(".emp-tab-button").on('click', function () {

          // Capture the active tab clicked
          var tabClicked = $(this).data("tab");


          // Check to make sure we're clicking a currently non-visible tab
          if (tabClicked !== previousActiveTabIndex) {

            // Look through each tab container
            $(".emp-tabs-container .emp-tab-contents").each(function () {

              // When we get the right tab, switch shown / hidden
              if( $(this).data("tab") === tabClicked ) {
                $(".emp-tab-contents").delay(200).hide().removeClass('fade-up-active');
                $(this).delay(200).show().addClass('fade-up-active');
                previousActiveTabIndex = $(this).data("tab");
                return;
              }
            });
          }
        });
      }
    },

    // QUESTIONS
    'questions': {
      init: function() {
        // QUESTIONS GROUPS WAYPOINTS (DYNAMIC)
        // Collect waypoints in case we need them
        var waypoints = [];

        // Question page loader
        var questionSets = $('.emp-question-set').length;

        $('.emp-question-set').each(function(index, value) {

          var that = $(value);

          var waypoint = new Waypoint({
            element: value,
            handler: function(direction) {
              if (direction === 'down') {
                that.addClass('fade-up-active');
              }
              if (direction === 'up') {
                that.removeClass('fade-up-active');
              }
            },
            offset: '70%'
          });
          waypoints.push(waypoint);
        });
      },
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
